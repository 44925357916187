import { type ReactElement } from 'react';
import { ChartBarLinesXy } from '../../../../atoms/charts';
import type { ChartBarLinesXYProps } from '../../../../atoms/charts/chartBarLinesXy/types';
import {useSiteConfig} from "../../../../../hooks/useSiteConfig";
import style from './chartBarLinesXyChunk.module.scss';

export function ChartBarLinesXyChunk({ data }: ChartBarLinesXYProps): ReactElement | null {
  const {config: {client }} = useSiteConfig();
  if(client?.platform.isMobile) return null
  
  return (
      <div className={style.wrapper}>
        <ChartBarLinesXy data={data} />
      </div>
  )
}
