import type { RefObject } from 'react';
import { useState, useEffect } from 'react';

type Dimensions = {
		width: number;
		height: number;
};

export function useResizeDivObserver(ref: RefObject<HTMLDivElement>): Dimensions {
		const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });
		
		useEffect(() => {
				const currentElement = ref.current;
				
				if (currentElement) {
						const resizeObserver = new ResizeObserver(entries => {
								entries.forEach(entry => {
										const { width, height } = entry.contentRect;
										setDimensions({ width, height });
								});
						});
						resizeObserver.observe(currentElement);
						return () => {
								resizeObserver.unobserve(currentElement);
						};
				}
				
		
		}, [ref]);
		
		return dimensions;
}
