import type {ReactElement} from "react";
import {CodeBlock} from "../../../../atoms/codeBlock";
import styles from './codeBlockChunk.module.scss';

export function CodeBlockChunk({fileName, code, language}: { fileName: string, code, language: string }): ReactElement | null {
		if (!fileName || !code || !language) return null;
		return (
					<div className={styles.wrapper}>
							<CodeBlock code={code} fileName={fileName} language={language}/>
					</div>
		);
}
