import {type ReactElement} from "react";
import {Copy, Check} from 'react-feather';
import {Highlight, themes} from "prism-react-renderer"
import {useCopyToClipboard} from "../../../hooks/useCopyToClipboard";
import styles from "./codeBlock.module.scss";
import type {CodeBlockProps} from "./types";

export function CodeBlock({code, language, fileName}: CodeBlockProps): ReactElement {
		
		
		
		const [copyStatus, copyToClipboard] = useCopyToClipboard(code);
		
		return (
					<div className={styles.wrapper}>
							<div className={styles.header}>
									<span>{fileName}</span>
									<button className="btn ghost" onClick={() => { copyToClipboard(); }} type="button">{copyStatus ? <Check/> : <Copy/>}</button>
							</div>
							<div className={styles.content}>
									<Highlight
												code={code}
												language={language}
												theme={themes.jettwaveDark}
									>
											{({style, tokens, getLineProps, getTokenProps}) => (
														<pre style={{...style, backgroundColor: "var(--uxu-color-background-default)", padding: "var(--uxu-space-default)"}}>
                 {tokens.map((line, i) => (
																				<div key={i} {...getLineProps({line})}>
																						<span style={{color: "var(--uxu-color-text-default)", opacity: "0.3", paddingRight: "var(--uxu-space-default)"}}>{i + 1}</span>
																						{line.map((token, key) => (
																									<span key={key} {...getTokenProps({token})} />
																						))}
																				</div>
																	))}
              </pre>
											)}
									</Highlight>
							</div>
					</div>
		)
}