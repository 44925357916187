import {type ReactElement} from "react";
import type {WindowProps} from "./types";
import styles from './window.module.scss';

export function Window({ title, children }: WindowProps): ReactElement {
		return (
					<div className={styles.wrapper}>
							<div className={styles.header}>
									<div className={styles.buttons}>
											<span />
											<span />
											<span />
									</div>
									{title ? <span className={styles.title}>{title}</span> : null}
							</div>
							<div className={styles.content}>
									{children}
							</div>
					</div>
		);
}
