import type { FC } from 'react';
import { CodeBlockChunk, EmbedChunk, EmbedYouTubeChunk, GalleryChunk, HeaderChunk, IMGChunk, ListChunk, ParagraphChunk, QuoteChunk, QuizChunk, TableChunk, AdChunk, ChartBarSeriesXyChunk, ChartBarLinesXyChunk } from '../chunks';
import { ContentPartEnum } from '../enums';

export const chunksMap = new Map<ContentPartEnum, FC>([
  [ContentPartEnum.PARAGRAPH, ParagraphChunk],
  [ContentPartEnum.HEADER, HeaderChunk],
  [ContentPartEnum.IMG, IMGChunk],
  [ContentPartEnum.QUOTE, QuoteChunk],
  [ContentPartEnum.QUIZ, QuizChunk],
  [ContentPartEnum.LIST, ListChunk],
  [ContentPartEnum.CODEBLOCK, CodeBlockChunk],
  [ContentPartEnum.EMBED, EmbedChunk],
  [ContentPartEnum.TABLE, TableChunk],
  [ContentPartEnum.GALLERY, GalleryChunk],
  [ContentPartEnum.EMBEDYOUTUBE, EmbedYouTubeChunk],
  [ContentPartEnum.AD, AdChunk],
  [ContentPartEnum.CHARTBARSERIESXY, ChartBarSeriesXyChunk],
  [ContentPartEnum.CHARTBARLINESXY, ChartBarLinesXyChunk],
]);
