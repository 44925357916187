import type {ReactElement} from 'react';
import type {ChunkProps} from '../../types';
import {Quiz} from "../../../../organisms/quiz";
import type {QuizProps} from "../../../../organisms/quiz/types";
import styles from './quizChunk.module.scss';

export function QuizChunk({question, answers}: ChunkProps<Partial<QuizProps>>): ReactElement | null {
		if (!question || !answers?.length) return null;
		return (
					<div className={styles.wrapper}>
							<Quiz answers={answers} question={question}/>
					</div>
		);
}
