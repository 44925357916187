import {type ReactElement, useState} from "react";
import {Window} from "../../atoms/window";
import {Confetti} from "../../molecules/confetti";
import {type QuizProps} from "./types";
import styles from './quiz.module.scss';

export function Quiz({question, answers}: QuizProps): ReactElement | null {
		const [answerResult, setAnswerResult] = useState<boolean | undefined>(undefined);
		
		function renderResultAnswer(): ReactElement | null {
				if (answerResult === true) {
						return <Confetti className={styles.wrapperGoodAnswer} title="Gratulacje, to była poprawna odpowiedź!"/>;
				} else if (answerResult === false) {
						return (
									<div className={styles.wrapperWrongAnswer}>
											<span>Niestety, to była błędna odpowiedź :(</span>
											<button className="btn" onClick={() => { setAnswerResult(undefined); }} type="button">
													Spróbuj jeszcze raz
											</button>
									</div>
						);
				}
				return null;
		}
		
		 if (!answers.length) return null
		
		return (
					<Window title={question}>
							{answerResult === undefined && (
										<div className={styles.wrapperAnswers}>
												{answers.map((ans) => {
														return (
																	<button className="btn" key={ans.answer} onClick={() => {setAnswerResult(ans.isTrue)}} type="button">{ans.answer}</button>
														)
												})}
										</div>
							)}
							{renderResultAnswer()}
					</Window>
		);
}
