import type { ReactElement } from 'react';
import { ChartBarSeriesXy } from '../../../../atoms/charts';
import type { ChartBarSeriesXYProps } from '../../../../atoms/charts/chartBarSeriesXy/types';
import {useSiteConfig} from "../../../../../hooks/useSiteConfig";
import style from './chartBarSeriesXyChunk.module.scss';

export function ChartBarSeriesXyChunk({ data }: ChartBarSeriesXYProps): ReactElement | null {
  const {config: {client}} = useSiteConfig();
  if(client?.platform.isMobile) return null
  return (
      <div className={style.wrapper}>
        <ChartBarSeriesXy data={data} />
      </div>
  )
}
